import {Component, EventEmitter, Output} from '@angular/core';
import {NavbarContentService} from '../navbar-content.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs';
import {DashboardService, UserInfo} from 'portal/pages/dashboard/dashboard.service';
import {AuthService} from 'portal/services/auth.service';
import {PermissionsService} from 'portal/services/permissions.service';
import {serializeGet} from 'shared/utils/utils';

@Component({
    selector: 'app-navbar',
    templateUrl: 'navbar.component.html',
})
export class NavbarComponent {
    @Output() toggleSidebar = new EventEmitter();

    userInfo$: Observable<UserInfo>;
    isAdmin: boolean;
    photo: Blob;
    modalRef: BsModalRef;
    navbarClass = '';

    get logoutUrl() {
        return `https://sso.unseco.ru/adfs/oauth2/logout?${serializeGet({
            'post_logout_redirect_uri': window.location.origin + '/login',
            'id_token_hint': AuthService.adfsIdToken,
        })}`;
    }

    constructor(
      public navbarContent: NavbarContentService,
      private dashboardService: DashboardService,
      private permissionsService: PermissionsService,
      private navbar: NavbarContentService,
    ) {
        this.userInfo$ = this.dashboardService.userInfo$;
        this.isAdmin = this.permissionsService.isAdmin();
        this.navbar.htmlClass$.subscribe(htmlClass => this.navbarClass = htmlClass);
    }

    toggle() {
        this.toggleSidebar.emit();
        this.navbarContent.toggle();
    }

    handleLogoutLinkClick() {
        window.location.href = this.logoutUrl;
    }
}
