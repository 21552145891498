import { Component, CUSTOM_ELEMENTS_SCHEMA, HostBinding, Input, NO_ERRORS_SCHEMA, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-user-photo',
  template: `
    <ng-container *ngIf="login">
      <div [routerLink]="['u', login]" class="wrapper" [ngClass]="{'bg-gray': error}">
        <img [src]="photoUrl" (error)="onPhotoLoadingError()" />
      </div>
    </ng-container>
    <ng-container *ngIf="!login">
      <div class="wrapper" [ngClass]="{'bg-gray': error}">
        <img [src]="photoUrl" (error)="onPhotoLoadingError()" />
      </div>
    </ng-container>
    `,
})

export class UserPhotoComponent implements OnChanges {
  @Input() photoUrl: string;
  @Input() userName: string;
  @Input() login: string;


  get initials() {
    if (this.userName) {
      return this.userName.split(' ').map((word) => word.charAt(0).toUpperCase()).join('');
    }
    return '';
  }

  @HostBinding('class.error')
  public error = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.error = false;
  }

  onPhotoLoadingError() {
    this.error = true;
  }
}
