import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgSelectConfig } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { MENU_ITEMS, menuItemsFactory } from 'portal/modules/navigation/navigation.service';
import { PortalNavigationModule } from 'portal/modules/navigation/portal-navigation.module';
import { UserPhotoModule } from 'portal/modules/user-photo/user-photo.module';
import { DashboardService } from 'portal/pages/dashboard/dashboard.service';
import { HappyBirthdayIliaComponent } from 'portal/pages/happy-birthday-ilia/happy-birthday-ilia.component';
import { PromptUpdateService } from 'portal/prompt-update.service';
import { BusinessCalendarService } from 'portal/services/business-calendar.service';
import { AcceptLanguageInterceptor } from 'portal/services/interceptors/accept-language.interceptor';
import { MediaQueryService } from 'shared/media-query.service';
import { SEARCHBAR_PLACEHOLDER } from 'shared/modules/searchbar/searchbar.component';
import { SentryErrorHandlerModule } from 'shared/modules/sentry-error-handler/sentry-error-handler.module';
import { PaginationService } from 'shared/modules/table/paginator/pagination.service';
import { SelectableService } from 'shared/modules/table/table-with-controls/control-cell/selectable.service';
import { setUpCypressAutomocker } from 'shared/utils/set-up-cypress-automocker';
import { release } from '../../../../release';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SharedModule } from './modules/shared/shared.module';
import { HrDocsComponent } from './pages/hr/hr-docs/hr-docs.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthService } from './services/auth.service';
import { EnumsService } from './services/enums.service';
import { TokenInterceptor } from './services/interceptors/auth.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { LoadingInterceptor } from './services/interceptors/loading.interceptor';
import { PermissionsService } from './services/permissions.service';
import {FILE_STORAGE_API_PREFIX} from 'shared/modules/download/download.service';
import {CheckForUpdateService} from 'portal/check-for-update.service';
import { NavbarModule } from "../../../../shared/modules/navigation/navbar/navbar.module";

registerLocaleData(localeRu, 'ru');

/**
 * Workaround for Safari, which doesn't support ISO 8601 date format
 * in DatePipe. Replaces whitespace with 'T' character.
 *
 * @remarks
 * This function patches DatePipe.prototype.transform method at runtime
 * to fix the issue. It's only applied if User Agent string contains
 * "Version" and "Safari" words.
 */
export function fixSafariDatePipe() {
    const isSafari = /.*Version.*Safari.*/.test(navigator.userAgent);
    if (isSafari) {
        const transform = DatePipe.prototype.transform;
        DatePipe.prototype.transform = function(...args) {
            args[0] = typeof (args[0]) === 'string' ? args[0].replace(/\s/g, 'T') : args[0];
            return transform.apply(this, args);
        };
    }
}

@NgModule({
    declarations: [
        AppComponent,
        MainLayoutComponent,
        HrDocsComponent,
        LoginComponent,
        HappyBirthdayIliaComponent,
    ],
    imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    PortalNavigationModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    SentryErrorHandlerModule.withSettings(environment.sentrySettings, release),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    UserPhotoModule,
    NavbarModule
],
    providers: [
        CheckForUpdateService,
        PromptUpdateService,
        AuthService,
        PermissionsService,
        EnumsService,
        MediaQueryService,
        DashboardService,
        { provide: LOCALE_ID, useValue: 'ru' },
        {
            provide: APP_INITIALIZER,
            useFactory: (auth: AuthService) => async () => {
                await setUpCypressAutomocker();
                // await AuthenticateByIP(auth);
            },
            deps: [AuthService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AcceptLanguageInterceptor,
            multi: true,
        },
        LoadingInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: LoadingInterceptor,
            multi: true,
        },
        {
            provide: SEARCHBAR_PLACEHOLDER,
            useValue: 'Поиск по разделу',
        },
        {
            provide: MENU_ITEMS,
            useFactory: menuItemsFactory,
            deps: [PermissionsService],
        },
        {
            provide: FILE_STORAGE_API_PREFIX,
            useValue: '/api/v2/filestorage',
        },
        SelectableService,
        PaginationService,
        BusinessCalendarService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(ngSelectConfig: NgSelectConfig) {
        fixSafariDatePipe();
        this.localizeNgSelect(ngSelectConfig);
    }

    localizeNgSelect(ngSelectConfig: NgSelectConfig) {
        ngSelectConfig.notFoundText = 'Нет значений';
        ngSelectConfig.clearAllText = 'Очистить';
        ngSelectConfig.loadingText = 'Загрузка';
        ngSelectConfig.typeToSearchText = 'Поиск';
    }
}
