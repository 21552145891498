import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { NavbarContentService } from "shared/modules/navigation/navbar-content.service";

import { NavbarComponent } from "./navbar.component";
import { UserPhotoModule } from "../../../../projects/portal/src/app/modules/user-photo/user-photo.module";
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [CommonModule, UserPhotoModule, RouterModule],
    declarations: [NavbarComponent],
    exports: [NavbarComponent]
})
export class NavbarModule {
    static forRoot(): ModuleWithProviders<NavbarModule> {
        return {
            ngModule: NavbarModule,
            providers: [NavbarContentService]
        };
    }
}
