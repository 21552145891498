<i class="fa fa-search"></i>
<div class="input-group flex-nowrap animated {{ (search && search.length) || show ? 'opacity-1' : '' }}">
    <ng-content select=".searchbar-right"></ng-content>
    <input *ngIf="!hideInput"
           type="text"
           class="form-control flex-grow-1"
           [placeholder]="placeholder"
           [(ngModel)]="search">
    <ng-content></ng-content>
</div>
