<button *ngIf="navbarContent.showToggler$ | async" class="navbar-toggler border-0" type="button"
        aria-label="Toggle navigation" (click)="toggle()">
    <span class="navbar-toggler-icon"></span>
</button>

<ng-container *ngIf="(navbarContent.template$ | async) as template">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
</ng-container>

<ng-container *ngTemplateOutlet="support"></ng-container>
<ng-container *ngTemplateOutlet="logout"></ng-container>

<ul *ngIf="userInfo$ | async as userInfo" class="d-none d-lg-block">
    <li class="nav-item dropdown">
        <a class="nav-link text-white" [routerLink]="'/u/'+userInfo.email">
            <ng-container *ngTemplateOutlet="avatar; context: { $implicit: userInfo }"></ng-container>
        </a>
    </li>
</ul>

<ng-template #support>
    <a href="mailto:portalsupport@mediainstinctgroup.ru" class="btn btn-navbar text-decoration-none">
        <i class="fas fa-hands-helping"></i> Поддержка портала
    </a>
</ng-template>

<ng-template #logout>
    <a [routerLink]="" container="body" tooltip="Выйти" (click)="handleLogoutLinkClick()"
        class="btn btn-navbar text-decoration-none">
        <i class="fas fa-sign-out-alt"></i>
    </a>
</ng-template>

<ng-template #avatar let-userInfo>
    <h5 class="profile-pic d-none d-lg-block">
        <span class="centered">{{ userInfo.name.slice(0, 1) }}</span>
        <app-user-photo [photoUrl]="userInfo?.employee?.photo_url" [login]="userInfo?.email"
            class="centered"></app-user-photo>
    </h5>
</ng-template>
