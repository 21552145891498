import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavbarContentService {
  private _template$: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject(null);
  template$ = this._template$.asObservable();

  private _htmlClass$ = new BehaviorSubject<string>('');
  htmlClass$ = this._htmlClass$.asObservable();

  private _htmlColor$ = new BehaviorSubject<string>('');
  htmlColor$ = this._htmlClass$.asObservable();

  private toggleSubject$ = new BehaviorSubject<boolean>(false);
  toggle$ = this.toggleSubject$.asObservable();

  private showTogglerSubject$ = new BehaviorSubject<boolean>(true);
  showToggler$ = this.showTogglerSubject$.asObservable();

  setTemplate(template: TemplateRef<any>) {
    this._template$.next(template);
    this._htmlClass$.next('');
  }

  setClass(htmlClass: string) {
    this._htmlClass$.next(htmlClass);
  }

  setColor(htmlColor: string) {
    this._htmlColor$.next(htmlColor);
  }

  removeTemplate() {
    this._template$.next(null);
  }

  toggle() {
    this.toggleSubject$.next(!this.toggleSubject$.value);
  }

  showToggler(val: boolean) {
    this.showTogglerSubject$.next(val);
  }

}
