import { Component, ElementRef, HostBinding, HostListener, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

const formatForMonthDayPicker = {
  parseInput: null,
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { month: 'long', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { month: 'long', day: 'numeric' },
  dateA11yLabel: { month: 'long', day: 'numeric' },
  monthYearA11yLabel: { month: 'long', day: 'numeric' },
};

/*tslint:disable component-class-suffix component-selector*/
@Component({
  selector: 'field-monthday',
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: formatForMonthDayPicker }],
  template: `
    <input class="form-control"
           #inputElement
           autocomplete="off"
           readonly
           [class.is-invalid]="showError"
           [formlyAttributes]="field"
           [formControl]="formControl"
           [owlDateTimeTrigger]="dt" 
           [owlDateTime]="dt"
    />
    <owl-date-time #dt [pickerType]="'calendar'" 
                   [startView]="'year'"
                   panelClass="monthday-picker"
    ></owl-date-time>
  `,
  styles: [`
    .form-control { background: #fff; }
    ::ng-deep .monthday-picker owl-date-time-calendar[data-view="year"] .owl-dt-calendar-control {
      display: none;
    }
    ::ng-deep .owl-dt-weekdays {
      display: none;
    }
    ::ng-deep .monthday-picker owl-date-time-calendar[data-view="month"] .owl-dt-calendar-control-content {
      pointer-events: none;
    }
    ::ng-deep .monthday-picker owl-date-time-calendar[data-view="month"] .owl-dt-control-button-arrow {
      display: none;
    }
  `],
})
export class MonthDayField extends FieldType {
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
  to: any;

  @HostBinding('class.cursor-pointer')
  cursorPointer = true;

  @HostListener('click')
  onClick() { this.inputElement.nativeElement.click(); }
}
